import React, { useState, useEffect } from 'react'
import useSlideAnimation from '../../hooks/useSlideAnimation'
import { isBrowser, getLineHeightFromScreenWidth } from './helper'
import { SLIDE_ANIMATION_CONSTANTS } from './const'
import DuneBlobPhoto from '../../../static/images/dune-blobpng.png'
import OutdoorBlobPhoto from '../../../static/images/outdoor-blobpng.png'
import ProfilePhoto from '../../../static/images/photo_shoot2.png'
import AboutMeHeader from '../../../static/images/header-about.svg'
import GreenBlob from '../../../static/aboutme-blobs/title-blob.svg'
import RedBlob from '../../../static/aboutme-blobs/adj-blob.svg'
import BlueBlob from '../../../static/aboutme-blobs/tiny-blob.svg'

import './styles/about-me.scss'

const AboutMe = () => {
  // when font size & line height shrinks we need to tell gsap so that the
  // sliding animation can still work accordingly
  const [gsapLineHeight, setGsapLineHeight] = useState(() => getLineHeightFromScreenWidth())
  useEffect(() => {
    const handleResize = () => {
      setGsapLineHeight(getLineHeightFromScreenWidth())
    }
    if (isBrowser()) {
      window.addEventListener('resize', handleResize)
    }
    return () => {
      if (isBrowser()) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  // slide animation for the three words used on about-me section
  useSlideAnimation(
    [
      { containerClass: '.title-slide', listClass: '.title-slides', duration: 2.2 },
      { containerClass: '.adj1-slide', listClass: '.adj1-slides', duration: 2.2 },
      { containerClass: '.adj2-slide', listClass: '.adj2-slides', duration: 2.2 },
    ],
    gsapLineHeight,
  )

  return (
    <div id="aboutme" class="aboutme_bg">
      <div class="aboutme_body workexp_body">
        <img class="dune-blob" src={DuneBlobPhoto} />
        <img class="outdoor-blob" src={OutdoorBlobPhoto} />
        <div class="row">
          <div class="pl-0 col-lg-12 col-md-12 col-s-12">
            <img class="aboutme_header" src={AboutMeHeader} />
          </div>
        </div>
        <div class="aboutme_container">
          <div class="row aboutme-row">
            <div class="aboutme-left-col col-lg-6 col-md-12 col-sm-12">
              <div class="aboutme-left-col-bg">
                <div class="aboutme-body">
                  <div class="aboutme-body-intro">
                    I'm a
                    <span id="title-blob-container">
                      <GreenBlob />
                      <div class="title-blob-animation-container">
                        <ul id="title-blob-job-title" className="title-slides v-slides">
                          <li className="title-slide v-slide">engineer</li>
                          <li className="title-slide v-slide">designer</li>
                          <li className="title-slide v-slide">developer</li>
                          <li className="title-slide v-slide">surfer</li>
                          <li className="title-slide v-slide">engineer</li>
                        </ul>
                      </div>
                    </span>
                    <span>from</span>&nbsp;
                    <span>Michigan</span>&nbsp;
                    <span>currently</span>&nbsp;
                    <span>living in </span>&nbsp;
                    <span>Santa Cruz.</span>
                  </div>
                </div>
                <div class="aboutme-body aboutme-body--newline aboutme-body-experiences">
                  {gsapLineHeight === SLIDE_ANIMATION_CONSTANTS.LARGE_DEVICE_LINEHEIGHT
                    ? `I like to make experiences that are `
                    : `I make experiences that are `}
                </div>
                <div class="aboutme-body aboutme-body--notopmargin">
                  <div class="aboutme-body-intro">
                    <div id="title-blob-description-container">
                      <RedBlob />
                      <BlueBlob />
                      <div
                        id="aboutme-adjective-1-container"
                        className="title-blob-animation-container"
                      >
                        <ul id="title-blob-job-title" className="adj1-slides v-slides">
                          <li className="adj1-slide v-slide">memorable</li>
                          <li className="adj1-slide v-slide">immersive</li>
                          <li className="adj1-slide v-slide">captivating</li>
                          <li className="adj1-slide v-slide">interesting</li>
                          <li className="adj1-slide v-slide">memorable</li>
                        </ul>
                      </div>
                      <div>and</div>
                      <div className="title-blob-animation-container">
                        <ul id="title-blob-job-title" className="adj2-slides v-slides">
                          <li className="adj2-slide v-slide">cool</li>
                          <li className="adj2-slide v-slide">funky</li>
                          <li className="adj2-slide v-slide">unique</li>
                          <li className="adj2-slide v-slide">stylish</li>
                          <li className="adj2-slide v-slide">cool</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aboutme-body aboutme-body--newline">
                  <span>
                    Take a look around and feel free to
                    <div>
                      <a href="#contactme">
                        <span className="aboutme-body--underline">
                          <div className="contactme-button">contact me</div>
                        </span>
                      </a>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="photo_shoot_container">
                <div class="gradient_block"></div>
                <img class="professional_photo" src={ProfilePhoto} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMe
