import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'
import Nav from '../../components/nav/nav'
import JamesBuecheHeader from '../../../static/images/jamesbueche.svg'
import HomeSectionFooter from '../../../static/images/home-divider.svg'
import BlueBlob from '../../../static/blobs/blue-home.svg'
import GreenBlob from '../../../static/blobs/green-home.svg'
import RedBlob from '../../../static/blobs/red-home.svg'
import OrangeBlob from '../../../static/blobs/orange-home.svg'

import './styles/home.scss'

const Home = () => {
  // Fade-in effect for home blobs
  useEffect(() => {
    let blobs = document.querySelectorAll(`.blob`)
    let name = document.querySelector(`.james_bueche_name`)

    blobs.forEach((blob, index) => {
      setTimeout(function () {
        blob.setAttribute('class', 'fades')
      }, getRandomArbitrary(0.25, 0.5) * 1000 * (index + 1))
      if (index === blobs.length - 1) {
        // setTimeout(function () {
        //   name.classList.add('fades')
        //   name.classList.remove('zero_opacity')
        // }, getRandomArbitrary(0.5, 0.75) * 1000 * (index + 1))
      }
    })
  }, [])

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min
  }

  return (
    <div class="home_bg">
      <Helmet>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
        <script src={withPrefix('card.js')} type="text/javascript" />
      </Helmet>
      <Nav />
      <BlueBlob />
      <GreenBlob />
      <RedBlob />
      <OrangeBlob />
      <div class="name_cont">
        <img class="james_bueche_name" src={JamesBuecheHeader} />
      </div>
      <div class="home-divider-container">
        <img class="home-divider" src={HomeSectionFooter} />
      </div>
    </div>
  )
}

export default Home
