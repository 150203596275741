import { useLayoutEffect, useState } from 'react'
import { gsap } from 'gsap'

const useSlideAnimation = (itemObjectArray, lineHeight = 50) => {
  const [timelines, setTimelines] = useState([])

  useLayoutEffect(() => {
    // if lineHeight changes that means screen width has shrunk
    // we need to clear the timeline and create a new timeline that
    // matches the new lineHeight
    timelines.forEach((timeline) => {
      timeline.clear()
    })
    let newTimelineArray = []
    itemObjectArray.forEach((itemObject) => {
      const { containerClass, listClass, duration } = itemObject
      var vsOpts = {
        slides: document.querySelectorAll(containerClass),
        list: document.querySelector(listClass),
        duration: duration,
        lineHeight: lineHeight,
      }
      const newTimeline = gsap.timeline({
        paused: true,
        repeat: -1,
      })

      vsOpts.slides.forEach(function (slide, i) {
        // Create a label
        let label = 'slide' + i
        newTimeline.add(label)

        if (i > 0) {
          // Move the whole word
          newTimeline.to(
            vsOpts.list,
            {
              duration: vsOpts.duration,
              y: i * -1 * vsOpts.lineHeight,
            },
            label,
          )

          newTimeline.addPause(label, function () {
            gsap.delayedCall(2.2, () => newTimeline.resume())
          })

          // Add some blank space before the next animation
          newTimeline.to({}, { duration: 1 })
        }
      })

      newTimeline.play()
      newTimelineArray = [...newTimelineArray, newTimeline]
    })
    setTimelines(newTimelineArray)
  }, [lineHeight])
}

export default useSlideAnimation
