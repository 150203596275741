import React, { useState, useEffect, useRef } from 'react'
import gsap from 'gsap'
import DesktopFlowImages from './flow-images/desktop-flow-images'
import MobileFlowImages from './flow-images/mobile-flow-images'
import MailchimpLogo from '../../../../static/images/mailchimplogo2.png'
import QbMcLogo from '../../../../static/images/qbmcj.png'
import LinkingImg1 from '../../../../static/images/linking1.png'
import LinkingImg2 from '../../../../static/images/linking2.png'
import SiteSpeedImg from '../../../../static/images/sitespeedexample.png'
import './mailchimp-content.scss'

const MailchimpContent = () => {
  const tablet_breakpoint = 700
  const [isTabletOrSmaller, setIsTabletOrSmaller] = useState(window.innerWidth < tablet_breakpoint)
  const sectionRefs = useRef([])

  useEffect(() => {
    const handleResize = () => {
      setIsTabletOrSmaller(window.innerWidth < tablet_breakpoint)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const fadeInSection = (section) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 2, ease: 'power3.out' },
      )
    }

    sectionRefs.current.forEach((section) => {
      const images = section.querySelectorAll('img')
      let loadedCount = 0

      const checkImagesLoaded = () => {
        loadedCount++
        if (loadedCount === images.length) {
          setTimeout(() => fadeInSection(section), 300)
        }
      }

      if (images.length === 0) {
        fadeInSection(section) // No images? Instantly show section.
      } else {
        images.forEach((img) => {
          if (img.complete) {
            checkImagesLoaded()
          } else {
            img.addEventListener('load', checkImagesLoaded)
            img.addEventListener('error', checkImagesLoaded) // Handle errors gracefully
          }
        })
      }
    })
  }, [])

  return (
    <>
      <div className="mailchimp-linking">
        <div
          className="mailchimp-section"
          ref={(el) => (sectionRefs.current[0] = el)}
          style={{ opacity: 0 }}
        >
          <div className="mailchimp-section__header">
            <img src={MailchimpLogo} alt="Mailchimp Logo" className="mailchimp-section__logo" />
            <h1 className="mailchimp-section__title">Senior Software Engineer</h1>
          </div>

          <div className="mailchimp-section__content">
            <div className="mailchimp-section__highlight">
              <div className="mailchimp-section__highlight-col">
                <h2 className="mailchimp-section__highlight-text">
                  10,000+{' '}
                  <span className="mailchimp-section__highlight-subtext">Accounts Linked</span>
                </h2>
                <img
                  src={QbMcLogo}
                  alt="Mailchimp Logo"
                  className="mailchimp-section__highlight-logo"
                />
              </div>
              <p className="mailchimp-section__description">
                QuickBooks+Mailchimp account linking synced user information between the
                applications for over 10,000 Mailchimp users. I created an interactive React UI that
                initiated the sync and displayed correct success/failure screens.
              </p>
            </div>
            <div className="mailchimp-section__images">
              <div className="mailchimp-section__image-wrapper-left">
                <img
                  src={LinkingImg1}
                  alt="Link Accounts First Image"
                  className="mailchimp-section__image"
                />
              </div>
              <div className="mailchimp-section__image-wrapper-right">
                <img
                  src={LinkingImg2}
                  alt="Link Accounts Second Image"
                  className="mailchimp-section__image mailchimp-section__image-linking2"
                />
              </div>
            </div>
            <p className="mailchimp-section__description mailchimp-section__description--align-right">
              I created an observability and monitoring dashboard using Bugsnag that highlighted
              areas of improvement for our UI. After realizing users were timing out, I developed a
              workaround that allowed a user to retry linking and drove conversion up ~12%.
            </p>
          </div>
        </div>
      </div>

      <div className="mailchimp-flows">
        <div
          className="mailchimp-section"
          ref={(el) => (sectionRefs.current[1] = el)}
          style={{ opacity: 0 }}
        >
          <div className="mailchimp-section__content">
            <div className="mailchimp-section__highlight">
              <p className="mailchimp-section__description mailchimp-section__description--align-right">
                I led the UI development in mailchimp’s effort to streamline sign-up and login
                experiences. I worked with Product, Design, and QA to create seamless sign-up/login
                flows from Quickbooks for mailchimp users. This work included accessibility best
                practices, contributing to shared component libraries, API integrations, and session
                cookie management.
              </p>
              <div className="mailchimp-section__highlight-col">
                <h2 className="mailchimp-section__highlight-text">
                  <span className="mailchimp-section__highlight-subtext">Created</span> and{' '}
                  <span className="mailchimp-section__highlight-subtext">optimized</span> new
                  sign-up and login
                </h2>
              </div>
            </div>
            <div className="mailchimp-section__images">
              {isTabletOrSmaller ? <MobileFlowImages /> : <DesktopFlowImages />}
            </div>
            <p className="mailchimp-section__description">
              I acted as the dedicated Front End team member on Mailchimp’s Identity team,
              responsible for maintaining all identity-related UIs. I was responsible for ad-hoc
              changes per Product to meet last-minute requests prior to deadlines. Following
              releases, I held learning sessions with our team on how to debug frontend issues using
              our observability tools.
            </p>
          </div>
        </div>
      </div>

      <div className="mailchimp-speed">
        <div
          className="mailchimp-section"
          ref={(el) => (sectionRefs.current[2] = el)}
          style={{ opacity: 0 }}
        >
          <div className="mailchimp-section__content">
            <div className="mailchimp-section__highlight">
              <div className="mailchimp-section__highlight-col">
                <h2 className="mailchimp-section__highlight-text">
                  <span className="mailchimp-section__highlight-subtext--block">875,000</span>
                  <span className="mailchimp-section__highlight-subtext">
                    site speed metrics
                  </span>{' '}
                  <span className="mailchimp-section__highlight-subtext">per day</span>
                </h2>
              </div>
              <p className="mailchimp-section__description">
                I led the use and platformization of Intuit’s App Fabric custom performance metrics
                in our Dojo and React web pages. These metrics track site speed across 200 mailchimp
                pages and gathers ~875,000 site speed datapoints daily.
              </p>
            </div>
            <div className="mailchimp-section__images">
              <div className="mailchimp-section__image-wrapper-left"></div>
              <div className="mailchimp-section__image-wrapper-right">
                <img
                  src={SiteSpeedImg}
                  alt="Site Speed Dashboard"
                  className="mailchimp-section__image mailchimp-section__image-sitespeed"
                />
              </div>
            </div>
            <p className="mailchimp-section__description mailchimp-section__description--align-right">
              By recording and monitoring our app’s Core Vitals, we provide invaluable insight to UX
              previously unavailable. This data can be leveraged to quickly identify pain points in
              the UX, drive quicker time to resolution for bugs which may have otherwise gone
              unnoticed, and inform future UX design decisions.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default MailchimpContent
