import React from 'react'
import './styles/overlay.scss'

const Overlay = ({ children, onClose }) => {
  return (
    <div className="overlay">
      <div className="overlay-content">
        <button className="close-btn" onClick={onClose}>
          ✕
        </button>
        {children}
      </div>
    </div>
  )
}

export default Overlay
