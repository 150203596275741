import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faCode } from '@fortawesome/free-solid-svg-icons'
import ProjectHeader from '../../../static/images/project_header.svg'
import InstagramLogo from '../../../static/images/instalog.png'
import PythonLogo from '../../../static/images/python_logo.png'
import FlaskLogo from '../../../static/images/flask_logo.png'
import ReactLogo from '../../../static/images/react_logo.png'
import BootstrapLogo from '../../../static/images/bootstrap_logo.png'
import PortfolioLogo from '../../../static/images/portfolio_logo.png'
import GSAPLogo from '../../../static/images/greensock_logo.png'
import IllustratorLogo from '../../../static/images/illustrator_logo.png'
import AmassLogo from '../../../static/images/amass.png'
import FreshKicksLogo from '../../../static/images/freshkicks_logo.png'
import GatsbyLogo from '../../../static/images/gatsby_logo.png'
import SassLogo from '../../../static/images/sass_logo.png'
import ThreeJsLogo from '../../../static/images/three_js_logo.png'

import './styles/projects.scss'

const Projects = () => (
  <div id="projects" class="project_container">
    <div class="projects-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-s-12">
          <img class="projects_header mt-11 aboutme_header" src={ProjectHeader} />
        </div>
      </div>
      <div class="row workexp_row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
          <div class="project_card_container">
            <div class="job_card">
              <div class="job_card_img_container" id="fresh_kicks_card_img_container">
                <div class="company_logo_container">
                  <img class="company_logo" id="fresh_kicks_logo" src={FreshKicksLogo} />
                </div>
              </div>

              <div
                class="job_card_description_container"
                id="fresh_kicks_card_description_container"
              >
                <div class="btn_container">
                  <div class="x"> </div>
                </div>
                <div class="job_card_position">
                  <span> Fresh Kicks </span>
                </div>
                <div class="job_card_detail_container">
                  <div className="job_card_detail_container_description">
                    <span>
                      {' '}
                      React Gatsby web application protoype for viewing store products and
                      information in a modern 3D fashion. Built with React, Three JS, React Three
                      Fiber, SASS.
                    </span>
                  </div>
                  <div className="job_card_detail_container_links">
                    <div className="job_card_detail_container_link">
                      <a target="_blank" href="https://freshkicks.jamesbueche.com">
                        <FontAwesomeIcon
                          className="job_card_detail_container_web_icon"
                          icon={faGlobe}
                          size="2x"
                          style={{ color: '#FFFFFF' }}
                        />
                        <span>Website</span>
                      </a>
                    </div>
                    <div className="job_card_detail_container_link">
                      <a
                        target="_blank"
                        href="https://bitbucket.org/buecheja/fresh-kicks/src/master/"
                      >
                        <FontAwesomeIcon
                          className="job_card_detail_container_code_icon"
                          icon={faCode}
                          size="2x"
                          style={{ color: '#FFFFFF' }}
                        />
                        <span>Code</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="job_card_tools_container">
                  <img class="tool_logo" id="gatsby_logo" src={GatsbyLogo} />
                  <img class="tool_logo" id="three_js_logo" src={ThreeJsLogo} />
                  <img class="tool_logo" id="sass_logo" src={SassLogo} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
          <div class="project_card_container">
            <div class="job_card">
              <div class="job_card_img_container" id="portfolio_card_img_container">
                <div class="company_logo_container">
                  <img class="company_logo" src={PortfolioLogo} />
                </div>
              </div>
              <div class="job_card_description_container" id="portfolio_card_description_container">
                <div class="btn_container">
                  <div class="x"> </div>
                </div>
                <div class="job_card_position">
                  <span> This Website </span>
                </div>
                <div class="job_card_detail_container">
                  <div className="job_card_detail_container_description">
                    <span>
                      My portfolio made on a React Gatsby SASS stack in combination with GSAP
                      animations. All SVGs are custom drawn in Adobe Illustrator and imported to the
                      site &#129312;
                    </span>
                  </div>
                  <div className="job_card_detail_container_links">
                    <div className="job_card_detail_container_link">
                      <a
                        target="_blank"
                        href="https://bitbucket.org/buecheja/portfolio/src/master/"
                      >
                        <FontAwesomeIcon
                          className="job_card_detail_container_code_icon"
                          icon={faCode}
                          size="2x"
                          style={{ color: '#FFFFFF' }}
                        />
                        <span>Code</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="job_card_tools_container">
                  <img class="tool_logo" id="gatsby_logo" src={GatsbyLogo} />
                  <img class="tool_logo" id="sass_logo" src={SassLogo} />
                  <img class="tool_logo" id="gsap_logo" src={GSAPLogo} />
                  <img class="tool_logo" id="illustrator_logo" src={IllustratorLogo} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
          <div class="project_card_container">
            <div class="job_card">
              <div class="job_card_img_container" id="instagram_card_img_container">
                <div class="company_logo_container">
                  <img class="company_logo" id="instagram_logo" src={InstagramLogo} />
                </div>
              </div>
              <div class="job_card_description_container" id="instagram_card_description_container">
                <div class="btn_container">
                  <div class="x"> </div>
                </div>
                <div class="job_card_position">
                  <span> Instagram Clone </span>
                </div>
                <div class="job_card_detail_container">
                  <span>
                    {' '}
                    Web application and Rest API developed to mimic the Instagram website and its
                    functionality
                  </span>
                </div>
                <div class="job_card_tools_container">
                  <img class="tool_logo" id="python_logo" src={PythonLogo} />
                  <img class="tool_logo" id="flask_logo" src={FlaskLogo} />
                  <img class="tool_logo" id="react_logo" src={ReactLogo} />
                  <img class="tool_logo" id="bootstrap_logo" src={BootstrapLogo} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
          <div class="project_card_container">
            <div class="job_card">
              <div class="job_card_img_container" id="amass_card_img_container">
                <div class="company_logo_container">
                  <img class="company_logo" id="amass_logo" src={AmassLogo} />
                </div>
              </div>

              <div class="job_card_description_container" id="amass_card_description_container">
                <div class="btn_container">
                  <div class="x"> </div>
                </div>
                <div class="job_card_position">
                  <span> amass </span>
                </div>
                <div class="job_card_detail_container">
                  <span>
                    {' '}
                    React web application engineered for Ann Arbor residents to post and find local
                    events.
                  </span>
                </div>
                <div class="job_card_tools_container">
                  <img class="tool_logo" id="react_logo" src={ReactLogo} />
                  <img class="tool_logo" id="bootstrap_logo" src={BootstrapLogo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Projects
