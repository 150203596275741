import React, { useState, useRef } from 'react'
import MailchimpContent from '../../components/overlay/mailchimp-content/mailchimp-content'
import Overlay from '../../components/overlay/overlay'
import PageTransition from '../../components/page-transition/page-transition'
import WorkExperienceDivider from '../../../static/images/projects_divider.svg'
import WorkExperienceHeader from '../../../static/images/workexp_header.svg'
import EbayLogo from '../../../static/images/ebay_logo.jpg'
import PhysioLogo from '../../../static/images/physio_logo.png'
import StrykerLogo from '../../../static/images/stryker_logo.jpg'
import MailchimpLogo from '../../../static/images/mailchimp_logo.png'

import './styles/work-exp.scss'

const WorkExperience = ({ isMobile }) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const pageSwitchRef = useRef()
  const handleReveal = () => {
    pageSwitchRef.current.reveal()
    document.body.style.overflow = 'hidden' // Disable scrolling
    setTimeout(() => {
      setShowOverlay(true)
    }, 1000)
  }

  const handleUnreveal = () => {
    pageSwitchRef.current.unreveal()
    setTimeout(() => {
      setShowOverlay(false)
      document.body.style.overflow = ''
    }, 1000)
  }
  return (
    <>
      {showOverlay && (
        <Overlay onClose={() => handleUnreveal()}>
          <MailchimpContent />
        </Overlay>
      )}
      <PageTransition ref={pageSwitchRef} isMobile={isMobile} />

      {/* <div ref={circleRef} className="custom-cursor">
        New view!
      </div> */}
      <div class="project-divider-container">
        <img class="project-divider" src={WorkExperienceDivider} />
      </div>
      <div class="workexp_container">
        <div id="work" class="workexp_body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-s-12">
              <img class="workexp_header" src={WorkExperienceHeader} />
            </div>
          </div>
          <div class="row workexp_row">
            <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
              <div class="job_date">NOV 2024 - JULY 2022</div>
              <div class="job_card_container">
                <div
                  className="job_card"
                  id="mailchimp-job_card"
                  // ref={(el) => (jobCardRefArray.current[0] = el)}
                  onClick={() => handleReveal()}
                >
                  <div class="job_card_img_container" id="mailchimp_card_img_container">
                    <div class="company_logo_container">
                      <img class="company_logo" id="mailchimp_logo" src={MailchimpLogo} />
                    </div>
                  </div>
                  <div
                    class="job_card_description_container"
                    id="mailchimp_card_description_container"
                  >
                    <div class="btn_container">
                      <div class="x"> </div>
                    </div>
                    <div class="job_card_position">
                      <span> Sr. Software Engineer </span>
                    </div>
                    <div id="mailchimp_headers" class="job_card_department_location"></div>
                    <div class="job_card_detail_container">
                      <span>
                        {' '}
                        Frontend engineering lead for Mailchimp’s Identity team. Managed a team of 3
                        to design, scope, and convert mailchimp pages from DojoJS to React. Created
                        fluid UI experiences for Mailchimp users to signup, login, and sync data
                        with Quickbooks, thus driving critical engagement metrics.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-s-6">
              <div class="job_date">JULY 2022 - AUG 2019</div>
              <div class="job_card_container">
                <div class="job_card">
                  <div class="job_card_img_container">
                    <div class="company_logo_container">
                      <img class="company_logo" id="ebay_logo" src={EbayLogo} />
                    </div>
                  </div>
                  <div class="job_card_description_container">
                    <div class="btn_container">
                      <div class="x"> </div>
                    </div>
                    <div class="job_card_position">
                      <span> Software Engineer </span>
                    </div>
                    <div class="job_card_department_location"></div>
                    <div class="job_card_detail_container">
                      <span>
                        {' '}
                        Developed mobile and desktop nodeJS/React web applications within eBay’s
                        Checkout team. Expanded our internal React custom component library by
                        creating payment-related components. I worked across domains to create
                        accessible, scalable interfaces for eBay Payments.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
              <div class="job_date">AUG 2018 - MAY 2018</div>
              <div class="job_card_container">
                <div class="job_card">
                  <div class="job_card_img_container" id="physio_card_img_container">
                    <div class="company_logo_container">
                      <img class="company_logo" id="physio_logo" src={PhysioLogo} />
                    </div>
                  </div>
                  <div
                    class="job_card_description_container"
                    id="physio_card_description_container"
                  >
                    <div class="btn_container">
                      <div class="x"> </div>
                    </div>
                    <div class="job_card_position">
                      <span> Software Intern </span>
                    </div>
                    <div
                      id="physio_headers"
                      class="job_card_department_location physio_subheaders_color"
                    ></div>
                    <div class="job_card_detail_container">
                      <span>
                        {' '}
                        I built a C# library and GUI for an AED automated testing suite used by
                        field specialists. This library utilized serial port programming and
                        facilitated asynchronous communication.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
              <div class="job_date">AUG 2017 - MAY 2017</div>
              <div class="job_card_container">
                <div class="job_card">
                  <div class="job_card_img_container" id="stryker_card_img_container">
                    <div class="company_logo_container">
                      <img class="company_logo" id="stryker_logo" src={StrykerLogo} />
                    </div>
                  </div>
                  <div
                    class="job_card_description_container"
                    id="stryker_card_description_container"
                  >
                    <div class="btn_container">
                      <div class="x"> </div>
                    </div>
                    <div class="job_card_position">
                      <span> Software Intern </span>
                    </div>
                    <div id="stryker_headers" class="job_card_department_location"></div>
                    <div class="job_card_detail_container">
                      <span>
                        {' '}
                        I engineered a full stack Python/LabVIEW Machine Vision Actuation Platform
                        and designed a GUI for full systems integration testing by Stryker Test Lab
                        Technicians.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkExperience
