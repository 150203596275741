import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import gsap from 'gsap'
import './styles.scss'
import { isMobile } from 'react-device-detect'
import {
  RIGHT_TO_LEFT_PATHS,
  LEFT_TO_RIGHT_PATHS,
  TOP_TO_BOTTOM_PATHS,
  BOTTOM_TO_TOP_PATHS,
} from './const'

const PageTransition = forwardRef((props, ref) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const overlayPathRef = useRef(null)

  const getTimeline = ({ paths }) => {
    return gsap
      .timeline({
        paused: true,
        onComplete: () => setIsAnimating(false),
        onReverseComplete: () => setIsAnimating(false),
      })
      .set(overlayPathRef.current, {
        attr: { d: paths.step1.unfilled },
      })
      .to(
        overlayPathRef.current,
        {
          duration: 0.8,
          ease: 'power3.in',
          attr: { d: paths.step1.inBetween },
        },
        0,
      )
      .to(overlayPathRef.current, {
        duration: 0.2,
        ease: 'power1',
        attr: { d: paths.step1.filled },
      })
      .set(overlayPathRef.current, {
        attr: { d: paths.step2.filled },
      })
      .to(overlayPathRef.current, {
        duration: 0.15,
        ease: 'sine.in',
        attr: { d: paths.step2.inBetween },
      })
      .to(overlayPathRef.current, {
        duration: 1,
        ease: 'power4',
        attr: { d: paths.step2.unfilled },
      })
  }

  const leftToRightTimeline = getTimeline({ paths: LEFT_TO_RIGHT_PATHS })
  const rightToLeftTimeline = getTimeline({ paths: RIGHT_TO_LEFT_PATHS })
  const bottomToTopTimeline = getTimeline({ paths: BOTTOM_TO_TOP_PATHS })
  const topToBottomTimeline = getTimeline({ paths: TOP_TO_BOTTOM_PATHS })

  const reveal = () => {
    if (isAnimating) return
    setIsAnimating(true)
    if (isMobile) {
      topToBottomTimeline.play(0)
    } else {
      leftToRightTimeline.play(0)
    }
  }

  const unreveal = () => {
    if (isAnimating) return
    setIsAnimating(true)
    if (isMobile) {
      bottomToTopTimeline.play(0)
    } else {
      rightToLeftTimeline.play(0)
    }
  }

  // Expose reveal and unreveal methods to parent component via ref
  useImperativeHandle(ref, () => ({
    reveal,
    unreveal,
  }))

  return (
    <div className={`transition-ctr ${isAnimating ? 'transition-ctr--visible' : ''}`}>
      <svg
        className="transition"
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path
          className="path"
          ref={overlayPathRef}
          vectorEffect="non-scaling-stroke"
          d={LEFT_TO_RIGHT_PATHS.step1.unfilled}
        />
      </svg>
    </div>
  )
})

export default PageTransition
