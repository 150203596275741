import React from 'react'
import SignupImg from '../../../../../static/images/mcsignup.png'
import LoginImg from '../../../../../static/images/mclogin.png'
import McQb1 from '../../../../../static/images/mcqb1.png'
import McQb2 from '../../../../../static/images/mcqb2.png'

const MobileFlowImages = () => (
  <>
    <img
      src={McQb2}
      alt="UI Screenshot 2"
      className="mailchimp-section__image mailchimp-section__image-mcqb2"
    />

    <div className="mailchimp-section__image-strict-size">
      <img
        src={SignupImg}
        alt="UI Screenshot 1"
        className="mailchimp-section__image mailchimp-section__image-signup"
      />
      <img
        src={LoginImg}
        alt="UI Screenshot 2"
        className="mailchimp-section__image mailchimp-section__image-login"
      />
    </div>

    <img
      src={McQb1}
      alt="UI Screenshot 1"
      className="mailchimp-section__image mailchimp-section__image-mcqb1"
    />
  </>
)

export default MobileFlowImages
