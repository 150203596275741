import { SLIDE_ANIMATION_CONSTANTS } from './const'

export const isBrowser = () => typeof window !== 'undefined'

export const getLineHeightFromScreenWidth = () => {
  if (isBrowser()) {
    return window.innerWidth <= SLIDE_ANIMATION_CONSTANTS.LINEHEIGHT_BREAKPOINT
      ? SLIDE_ANIMATION_CONSTANTS.SMALL_DEVICE_LINEHEIGHT
      : SLIDE_ANIMATION_CONSTANTS.LARGE_DEVICE_LINEHEIGHT
  }
  return SLIDE_ANIMATION_CONSTANTS.LARGE_DEVICE_LINEHEIGHT
}
